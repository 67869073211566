import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import multiple from '../components/multiple-room.vue'
import compVoice from '../components/comp_voice.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // 一对一视频通话
  {
    path: '/invite',
    name: 'Invite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue'),
  },
  // 多人视频通话
  {
    path: '/multiple',
    name: 'multiple',
    component: multiple
  },
  // 语音通话 11
  {
    path: '/voice',
    name: 'voice',
    component: compVoice
  },
];

const router = new VueRouter({
  routes,
});

export default router;
