<template>
  <div class="rtc-container">
    <!-- <el-button class="button" type="primary" size="small" :disabled="isJoining || isJoined" @click="handleJoinRoom">{{ $t('Join Room') }}</el-button> -->
    <!-- 进房操作区域
    <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p>
    <div class="control-container">
      <div class="rtc-control-container">
        进入房间
        <el-button class="button" type="primary" size="small" :disabled="isJoining || isJoined" @click="handleJoinRoom">{{ $t('Join Room') }}</el-button>
        <el-button v-if="isHostMode" class="button" type="primary" size="small" :disabled="isPublishing || isPublished" @click="handlePublish">{{ $t('Publish') }}</el-button>
        <el-button v-if="isHostMode" class="button" type="primary" size="small" @click="handleUnpublish">{{ $t('Unpublish') }}</el-button>
        离开房间
        <el-button class="button" type="primary" size="small" @click="handleLeave">{{ $t('Leave Room') }}</el-button>
      </div>
      远程控制
      <div v-if="isHostMode" class="screen-share-control-container">
        <el-button class="button" type="primary" size="small" :disabled="isShareJoined && isSharePublished" @click="handleStartScreenShare">{{ $t('Start Screen Share') }}</el-button>
        <el-button class="button" type="primary" size="small" @click="handleStopScreenShare">{{ $t('Stop Screen Share') }}</el-button>
      </div>
    </div> -->

    <!-- 显示邀请链接 -->
    <!-- <div v-if="showInviteLink" class="invite-link-container">
      <span v-if="isEnLang">Copy the link to invite friends to join the video call, one link can invite only one person,
        the link will be updated automatically after copying.</span>
      <span v-else>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip :visibleArrow="false" effect="dark" content="Copied!" placement="bottom" :manual="true" v-model="showCopiedTip">
            <span class="invite-btn" @click="handleCopyInviteLink">
              <svg-icon icon-name="copy"></svg-icon>
            </span>
          </el-tooltip>
        </template>
      </el-input>
    </div> -->

    <div class="info-container" :class="$isMobile && 'info-container-mobile'">
      <!-- Log 展示区域 -->
      <!-- <div v-if="isHostMode" class="log-container" ref="logContainer">
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div> -->
      <!-- v-if="localStream" -->
      <!-- 本地流区域 -->
      <div class="local-stream-container">
        <!-- 远端流区域  yuanduan-->
        <div class="remote-container ">
          <!-- remoteStreamList" :key="item.getUserId()" :id="item.getUserId() -->
          <div v-for="(item) in remoteStreamList" :key="item.getUserId()" :id="item.getUserId()" class="remote-stream-container " @click="currentVideo=!currentVideo" :class="{'maxBox':currentVideo==true,'yuanduan':currentVideo==false}">
          </div>
          <!-- 本地流播放区域 local-stream-content  v-if="localStream"-->
          <div id="localStream" v-if="localStream" class="remote-stream-container  local-stream-content" @click="currentVideo=!currentVideo" :class="{'maxBox':currentVideo==false,'yuanduan':currentVideo==true}">
          </div>
        </div>
        <!-- 本地流操作栏 -->
        <div class="local-stream-control" v-if="isPlayingLocalStream">
          <!-- 视频控制 -->
          <div class="one">
            <div class="video-control control">
              <div class="control_border" v-if="!isMutedVideo" @click="muteVideo">
                <img class="controller_img" src="../assets/images/camera-true.png" alt="">
              </div>
              <div class="control_border" v-if="isMutedVideo" @click="unmuteVideo">
                <img class="controller_img" src="../assets/images/camera-false.png" alt="">
              </div>
            </div>
            <!-- 声音控制 -->
            <div class="audio-control control">
              <div class="control_border" v-if="!isMutedAudio" @click="muteAudio">
                <img class="controller_img" src="../assets/images/speaker-true.png" alt="">
              </div>
              <div class="control_border" v-if="isMutedAudio" @click="unmuteAudio">
                <img class="controller_img" src="../assets/images/speaker-false.png" alt="">
              </div>
            </div>
            <!--摄像头前后切换 -->
            <div class="audio-control control">
              <div class="control_border">
                <img class=" controller_img" @click="toggleCreamer" src="../assets/images/switch.png" alt="">
              </div>
            </div>
          </div>
          <!-- 挂断 -->
          <div class="tow">
            <div class="audio-control control  guaduan" @click="handleLeave">
              <div class="control_border">
                <img class="controller_img" src="../assets/images/hangup-red.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="loading">
      <img src="../assets/images/loading.png" alt="">
    </div>

  </div>
</template>

<script>
import { Loading } from "element-ui";
import rtc from "./mixins/rtc.js";
import TRTC from "trtc-js-sdk";
import shareRtc from "./mixins/share-rtc.js";
import LibGenerateTestUserSig from "@/utils/lib-generate-test-usersig.min.js";
export default {
  name: "compRoom",
  mixins: [rtc, shareRtc],
  props: {
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  data() {
    return {
      currentVideo: true,
      logList: [],
      inviteLink: "",
      showCopiedTip: false,
      // sdkAppId: 1600047419,
      // secretKey:
      //   "d02da775e4d51d366a6477f6df5bd8505c434bad5f006e7ceca92175366837bf",
      // userId: "99999",
      // roomId: 8888, // 会议ID
      isCreamer: false,
      loading: null,
    };
  },
  computed: {
    isHostMode() {
      return this.type !== "invite";
    },
    isEnLang() {
      return this.$i18n.locale === "en";
    },
    showInviteLink() {
      return (
        this.isHostMode &&
        (this.isJoined || this.isShareJoined) &&
        this.inviteLink
      );
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice("video", val);
    },
    microphoneId(val) {
      this.switchDevice("audio", val);
    },
    isPlayingLocalStream(val) {
      if (val == true) {
        this.loading.close();
      }
    },
  },
  mounted() {
    this.handleJoinRoom();
    this.loading = Loading.service({
      lock: true,
      text: "正在加载中",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.1)",
    });
  },
  methods: {
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(
        sdkAppId,
        secretKey,
        604800
      );
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(
        `${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`
      );
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t("Please enter sdkAppId and secretKey"));
          return;
        }
        if (!this.userId || !this.roomId) {
          alert(this.$t("Please enter userId and roomId"));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(
          this.sdkAppId,
          this.secretKey,
          604800
        );
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (
          !this.sdkAppId ||
          !this.inviteUserSig ||
          !this.userId ||
          !this.roomId
        ) {
          alert(this.$t("Please reacquire the invitation link"));
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
    },
    // 切换播放区域
    toggleContainer() {},

    // 切换摄像头
    async toggleCreamer() {
      this.isCreamer = !this.isCreamer;
      let that = this;
      TRTC.getCameras().then((devices) => {
        if (this.isCreamer) {
          that.switchDevice("video", devices[0].deviceId);
        } else {
          that.switchDevice("video", devices[1].deviceId);
        }
      });
    },
    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave();
      uni.navigateBack();
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t("Please enter sdkAppId and secretKey"));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: "success",
        log,
      });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: "failed",
        log,
      });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: "webrtcQuickDemoVue2",
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = "rtc") {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${
          type === "share" ? this.shareUserId : this.userId
        }*${error.message}`,
        ext2: "webrtcQuickDemoVue2",
        ext3: this.sdkAppId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 页面最外面的容器
.rtc-container {
  width: 100%;
  min-height: 100vh;

  overflow: hidden;
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }
  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }
  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }
  .info-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    // 播放容器
    .local-stream-container {
      background-color: #ccc;
      width: 100%;
      min-height: 100vh;
      position: relative;
      flex-shrink: 0;
      // 本地播放的容器
      .local-stream-content {
      }
      .local-stream-control {
        width: 100%;
        height: 200px;
        position: absolute;
        z-index: 999;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 0 10px;
        .one {
          margin-top: 20px;
          display: flex;
          justify-content: space-around;
        }
        .tow {
          margin-top: 20px;
          display: flex;
          align-content: center;
          justify-content: center;
        }
        .control_border {
          padding: 10px;
          background-color: #fff;
          border-radius: 50%;

          .controller_img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }

        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
  }
  // 远端流主容器
  .remote-container {
    width: 100%;
    min-height: 100vh;

    // padding: 2%;
    display: flex;
    flex-wrap: wrap;

    .remote-stream-container {
      width: 35%;
      height: 23%;
      margin: 1%;
    }
  }
}
.minBox {
  width: 100%;
  height: 40%;
}
.maxBox {
  width: 100% !important;
  min-height: 100vh !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}
.yuanduan {
  position: absolute;
  right: 0%;
  top: 2%;
  background-color: #fff !important;
  z-index: 989;
}
.loading {
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
